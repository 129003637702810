.App {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  color: #577083;
  font-family: 'Open Sans',sans-serif;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: white;
  color: #00BFFF;
  font-size: 150%;
  position: absolute;
  left: 0;
  top: 0;
}

.popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.month {
  margin: 10px 10px 20px 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.header {
  background-color: #00BFFF;
  color: white;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  font-size: 110%;
  font-weight: 600;
  padding: 10px;
}
.day {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin: 5px;
  padding: 10px;
  font-size: 14px;
}
.day:hover {
  background-color: lightgray;
}
.day.active {
  background-color: rgba(26, 118, 204, 0.8);
  color: white;
}
.popup {
  z-index: 2;
  background-color: rgba(255,255,255,0.8);
  backdrop-filter: blur(5px);
  position: fixed;
  display: flex;
  top: 100px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60%;
  height: 40%;
}
.popup-header {
  display: flex;
  flex-direction: row;
  font-size: 120%;
  text-align: center;
  margin: 0 25px 0 25px;
}
.popup-buttons {
  display: flex;
  flex-direction: row;
  margin: 10px;
  padding: 10px;
  cursor: pointer;
}
.popup-buttons > * {
  font-size: 130%;
  font-weight: 500;
  margin: 0 20px 0 20px;
}
.yes-button {
  color: #00BFFF;
}
.shadow {
  -webkit-box-shadow: 0px 3px 14px -8px rgba(0,0,0,1);
  -moz-box-shadow: 0px 3px 14px -8px rgba(0,0,0,1);
  box-shadow: 0px 3px 14px -8px rgba(0,0,0,1);
}

.break {
  flex-basis: 100%;
  height: 0;
}
